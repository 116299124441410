import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledCertifications = styled.div`
  
  .certifcation {
    padding-bottom: 1em;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-row: auto;
    place-items: center;
  }

`;

  


const StyledPic = styled.div`
  position: relative;
  max-width: 100px;


  @media (max-width: 768px) {
    margin: 10px auto 0;
    width: 100%;
  }

  .wrapper {
    display: block;
    position: relative;
    background-color: transparent;

    &:hover,
    &:focus {
      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      width: 100%;
      height: 100%;
      transition: var(--transition);
    }


  }
`;

const Certification = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);


  return (
    <StyledCertifications id="certifcation" ref={revealContainer}>
      <div className="certifcation">
        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="https://d1.awsstatic.com/training-and-certification/certification-badges/AWS-Certified-Cloud-Practitioner_badge.634f8a21af2e0e956ed8905a72366146ba22b74c.png"
              width={170}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="https://d1.awsstatic.com/training-and-certification/certification-badges/AWS-Certified-Solutions-Architect-Associate_badge.3419559c682629072f1eb968d59dea0741772c0f.png"
              width={170}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="https://d1.awsstatic.com/training-and-certification/certification-badges/AWS-Certified-Developer-Associate_badge.5c083fa855fe82c1cf2d0c8b883c265ec72a17c0.png"
              width={170}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="https://d1.awsstatic.com/training-and-certification/certification-badges/AWS-Certified-Machine-Learning-Specialty_badge.e5d66b56552bbf046f905bacaecef6dad0ae7180.png"
              width={170}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="https://d1.awsstatic.com/training-and-certification/certification-badges/AWS-Certified-Data-Analytics-Specialty_badge.c74203ecf6d7c4889d01d8b4ba5c9df6e69c6f43.png"
              width={170}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="https://databricks.com/wp-content/uploads/2022/04/Specialty-badge-spark-developer-associate-2x.png"
              width={90}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="https://databricks.com/wp-content/uploads/2022/04/Associate-badge-eng-2x.png"
              width={90}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="https://images.credly.com/images/f88d800c-5261-45c6-9515-0458e31c3e16/ckad_from_cncfsite.png"
              width={170}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>

      </div>
    </StyledCertifications>
  );
};

export default Certification;
